

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faLocation, faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons";
// // import { ReactDOM } from "react";

const Contact = ()=>{
return(    <div>
    <div className="container">
        <div className="row" id="contact">
             <h1 className="text-center mb-5">contact us</h1><hr />
             <div className="col-md-6" id="contact-div">
                <h5 className="text-center">For all enquires, get in touch through</h5>
                <div id="contact-icon">
                    <ul>
                        <li data-aos="fade-down" data-aos-delay="200"><FontAwesomeIcon icon={faLocation}/> Location: AK-139-5937</li>
                        <li data-aos="fade-down" data-aos-delay="200"><FontAwesomeIcon icon={faMailBulk}/> Email: belovedartisanltd@gmail.com</li>
                        <li data-aos="fade-down" data-aos-delay="100"><FontAwesomeIcon className="text-dark" icon={faPhone}/> Phone: +233 272006720 </li>
                        <li data-aos="fade-down" data-aos-delay="300"><FontAwesomeIcon className="text-success" icon={faWhatsapp}/> WhatsApp: +233 272006720</li>
                        <li data-aos="fade-down" data-aos-delay="500"><a href="https://web.facebook.com/people/Belovedartisanltd/61559124897134/" target="blank"><FontAwesomeIcon className="text-primary" icon={faFacebook}/> Facebook: @belovedartisanltd</a></li>
                        <li data-aos="fade-down" data-aos-delay="100"><a href="https://www.instagram.com/belovedartisanltd/" target="blank"><FontAwesomeIcon className="text-danger" icon={faInstagram}/> Instagram: @belovedartisanltd</a></li>
                    </ul>
                </div>
             </div>
             <div className="col-md-6">
                <h5 className="text-center">Send us a message</h5>
                <div>
                    <form action="" method="">
                       <div class="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                 <input type="text" className="form-control" id="inputPassword3"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="inputEmail3" className="col-sm-2 col-form-label">Email</label>
                            <div class="col-sm-10">
                            <input type="email" className="form-control" id="inputEmail3"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="inputPassword3" className="col-sm-2 col-form-label">Message</label>
                            <div class="col-sm-10">
                                <textarea name="" id="" cols="30" rows="10" className="form-control"></textarea>
                            {/* <input type="password" class="form-control" id="inputPassword3"/> */}
                            </div>
                        </div>
                        
                       
                        <button type="" class="btn btn-primary">Submit</button>

                    </form>
                </div>
             </div>

        </div>
    </div>
        
    </div>)
}

export default Contact