import React from "react";
// import solar from "../imgs/solar-panel.jpg"

const Home = ()=>{
return(    <div>
    <div className="container-fluid">
        <div className="row" id="home">
           
            <h1>Welcome to beloved artisan limited company </h1>
        </div>
    </div>
        
        {/* <Link to="/service">Services</Link> */}
    </div>)
}

export default Home