import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../imgs/logo.jpg"
import { faPhone } from "@fortawesome/free-solid-svg-icons";


function NavbarPage() {
  return (
   <>
    <nav id="navbar" className="navbar navbar-expand-lg sticky-top">
      <div className="container">
        <a className="navbar-brand" href="#home">
          <img src={logo} alt=""  width={50} height={50}/>
        </a>
        <div>
          <button class="btn btn-outline-warning"><FontAwesomeIcon icon={faPhone}/><a href="tel:+233272006720">+233 272006720</a> </button>
            
             
          </div>
        <button className="navbar-toggler bg-warning" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#home">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">Who We Are</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#service">What We Do</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#project">Our Works</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">Contact Us</a>
            </li>
            {/* <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" aria-disabled="true">Disabled</a>
            </li>
          // </ul>*/}
          {/* <form class="d-flex mr-0" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            <button class="btn btn-outline-success" type="submit">Search</button>
          </form>  */}

          </ul>
          
        </div>
        
      </div>
    </nav>
   </>
  );
}

export default NavbarPage;