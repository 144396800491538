import React from "react";
 import solar1 from "../imgs/solar-1.jpg"
import car from "../imgs/car.webp"
import car2 from "../imgs/auto-elec.jpg"
import solar3 from "../imgs/solar-ser.jpg"
import solar2 from "../imgs/solar-2.jpg"
import solar4 from "../imgs/solar-4.jpg"
import solar5 from "../imgs/solar-5.jpg"
import roof1 from  "../imgs/roof-1.jpg"

// // import { ReactDOM } from "react";

const Project = ()=>{
return(    <div>
    <div className="container-fluid">
        <div className="row" id="project">
        <h1>Our Projects & Clients</h1>
        <p className="py-5">Beloved Artisan Limited Company is a project-based business that provides specialized consulting services to help clients bring products or technology to reality. We have more experience in the past years working with specialization in building and construction, solar system installation, automotive mechanical works, automotive electrical works, plumbing, and piping. 
        The company provides integrated engineering services and specialized engineering consulting to offer unique solutions to any project.
We are working with either the private clients, commercial clients, quasi-governmental clients or the government as our client.	</p>
        <hr />
        <div className="row">
            <div className="col-md-4">
                <div className="card">
                    <div className="card-body">
                    <img className="img-fluid" src={car2} alt=""/>
                    </div>
                    <div className="card-head">
                        <h4>Automotive Electrical Repairs for Davdery Venture</h4>
                        <p>Davdery Venture is one of our clients that we provides monthly vehicle check ups and maintaince for the company. </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
            <div className="card">
                    <div className="card-body">
                        <img className="img-fluid" src={solar3} alt="" />
                    </div>
                    <div className="card-head">
                        <h4>Solar installation for Good-Code Technologies</h4>
                        <p>this project was done for Good-Code Technologies at avoid power cut during production time at factory premises</p>
                    </div>
                </div>
            </div>
            {/* <div className="col-md-3">
            <div className="card">
                    <div className="card-body">
                    <img className="img-fluid" src={solar} alt="" />
                    </div>
                    <div className="card-head">
                        <h4>Solar installation at Ayum Forest Product ltd</h4>
                        <p>this project was done for AFPL at avoid power cut during production time at factory premises</p>
                    </div>
                </div>
            </div> */}
            <div className="col-md-4">
            <div className="card">
                    <div className="card-body">
                    <img className="img-fluid" src={car} alt="" />
                    </div>
                    <div className="card-head">
                        <h4>Vechile Service for Fred-Oppong Enterprise</h4>
                        <p>A regular Automotive service is always done Fred-Oppong Enterprise </p>
                    </div>
                </div>
            </div>
        </div>
        <br /><br />
        <div className="row py-5 my-5 bg-warning">
            <div className="col-md-8 mr-auto">
            <img className="img-fluid shadow rounded" src={solar2} alt="" width={800} height={30} />
            </div>
            <div className="col-md-4 mr-auto">
                <h1 className="py-5">Solar Panel installation </h1>
                <hr />
                <h5>Testimony</h5>
                <p>Working with Beloved Artisan Limited Company was the best return on investment I made on my building ~ by Mr. Andrews Yankey</p>
            </div>
            
        </div>
        <div className="row py-5 my-5  shadow">
              <div className="row ">
                <h4>Gallary</h4>
               <div className="row">
                <div className="col-md-3">
                    <div className="card">
                    <div className="card-body">
                    <img className="img-fluid" src={roof1} alt="" />
                    </div>
                </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                    <div className="card-body">
                    <img className="img-fluid" src={solar4} alt="" />
                    </div>
                </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                    <div className="card-body">
                    <img className="img-fluid" src={solar5} alt="" />
                    </div>
                </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                    <div className="card-body">
                    <img className="img-fluid" src={solar1} alt="" />
                    </div>
                </div>
                </div>
               
                
                
       
               </div>
                 
                </div>
        </div>
        </div>
        <div className="row">
            <h1>Our Clients</h1>

        </div>
        <div className="container my-5">
            <div className="row">
                <div className="col-md-4 ">
                    <div className="card shadow">
                        <div className="card-body bg-danger text-light">
                            Fred-Oppong Enterprise
                        </div>
                    </div>
                </div>
                <div className="col-md-4  ">
                    <div className="card shadow">
                        <div className="card-body bg-success text-warning">
                            Good-Code Technologies
                        </div>
                    </div>
                </div>
                <div className="col-md-4 ">
                    <div className="card shadow">
                        <div className="card-body">
                            DavDery Venture
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    </div>)
}

export default Project