import React from "react";
import logo from "../imgs/logo.jpg"

const About = ()=>{
return(    <div>
    <div className="container">
        <div className="row" id="about">
            <h1 className="mb-5"> Who We Are</h1><hr />
            <div className="col-md-6">
                <div className="text-center my-4">
                     <img src={logo} alt="logo" width={200} height={200}/>
                </div>
                   
                    <div className="row">
                    <p>
                    <span className="text-danger h5">
                    Beloved Artisan Limited Company
                    </span> is one of the leading multi-functional engineering company with our clients dreams at the heart of our focus
                    .  We have more experience in the past years working with specialization in building and construction, solar system installation, automotive mechanical works, automotive electrical works, plumbing, and piping.
                </p>
                    </div>
            </div>
            <div className="col-md-6">
                <div className="card shadow">
                    <div className="card-body">
                        <div className="row" data-aos="fade-down" data-aos-delay="200">
                            <h3>Our Mission</h3>
                            <p>To create value for our customers by providing exceptional service while using a professional approach.  We strive to deliver superior quality projects to our clients while meeting budget and schedule goals.</p>
                        </div>
                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                            <h3>Our Vision</h3>
                            <p>Our vision is to be widely recognized within the community for our continuing commitment to customer service and client satisfaction.</p>
                            
                            <h5>Our core Values</h5>
                            <ul className="ms-3">
                                <li data-aos="fade-up" data-aos-delay="200">
                                    <p><span className="text-danger">Integrity – </span> 
                                      we employ the highest ethical standards and believe that our continued success is based upon sustaining the relationships that keep our business growing. We believe that open and honest communication is vital to the lasting success of the firm.
                                      </p>
                                </li>
                                
                                <li data-aos="fade-up" data-aos-delay="200">
                                    <p> <span className="text-danger">Efficiency – </span>  we remain accountable to our clients for the efficient execution of any project.  We will work to identify and execute cost savings wherever we can.
                                    </p> 
                               </li>
                               
                                <li data-aos="fade-up" data-aos-delay="200">
                                     <p> <span className="text-danger">Teamwork –</span>  we believe that an open culture characterized by mutual respect, sharing of ideas and a commitment to common goals contributes to the overall productivity of our firm.  Our philosophy is that each team member plays an important role in our company by providing individual expertise and a distinctive perspective to each project that they are involved in.
                                    </p>
                                </li>
                                
                                <li data-aos="fade-up" data-aos-delay="200">
                                    <p> <span className="text-danger">Professionalism –</span>  in providing professional service we are dedicated to competence, dependability and excellence.  We believe that our projects are a reflection of who we are as a company.
                                    </p> 
                               </li>

                                <li data-aos="fade-up" data-aos-delay="200">
                                    <p><span className="text-danger">Customer Service –</span> we are dedicated to not only achieving, but exceeding, client expectations.  Through a strong commitment to the above values, Beloved Artisan Limited Company strives to set ourselves above the rest.
                                    </p>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
    </div>
        
    </div>)
}

export default About
