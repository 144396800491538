// import { Outlet} from "react-router-dom";
import NavbarPage from "./NavbarPage";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";
import Service from "./Service";
import Contact from "./Contact";
import Project from "./Project";

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();
// import NavDropdown from 'react-bootstrap/NavDropdown';

const Layout = () => {
  return (
    <div>
      <NavbarPage />
      <Home/>
      <About/>
      <Service/>
      <Project/>
      <Contact/>
      {/*use for routings <Outlet /> */}
      <Footer />
    </div>
  )
};

export default Layout;