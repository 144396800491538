
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import logo from "../imgs/logo.jpg"
const Footer = ()=>{
    return (
        <div>
            <div className="container-fluid">
                <div className="row" id="footer-base" >
                    <div className="col-md-4">
                    <img src={logo} alt="" width={80} height={80} />
                    <p className="my-5">
                    <span className="text-danger h5">
                    Beloved Artisan Limited Company  
                    </span> is one of the leading multi-functional engineering company with our clients dreams at the heart of our focus.
                    We have more experience in the past years working with specialization in building and construction, solar system installation, automotive mechanical works, automotive electrical works, plumbing, and piping.
               
                     </p>
                    </div>
                    <div className="col-md-4" 
                    
                    data-aos="fade-down"
                    data-aos-offset="200"
                    data-aos-delay="500"
                    data-aos-easing="ease-in-sine">
                       <h4>Quick Links </h4> 
                        <ul>
                            <li><a href="#about">Who We Are</a></li>
                            <li className="py-3"><a href="#service">What We Do</a></li>
                            <li className="pb-3"><a href="#project">Our Project & Clients</a></li>
                            <li><a href="#contact">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                     <h4>Social </h4> 
                        <ul>
                            <li><a href="tel: +233 272006720" ><FontAwesomeIcon className="text-success" icon={faWhatsapp}/> +233 272006720</a></li>
                            <li className="py-3"><a href="https://web.facebook.com/people/Belovedartisanltd/61559124897134/" target="blank"><FontAwesomeIcon className="text-primary" icon={faFacebook}/> belovedartisanltd</a></li>
                            <li><a href="https://www.instagram.com/belovedartisanltd/" target="blank"><FontAwesomeIcon className="text-danger" icon={faInstagram}/> @belovedartisanltd</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row" id="footer-main">
                    <div className="text-center">
                     <small> <h6>&copy; Beloved Artisan | All Rights Reserved</h6></small>  
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Footer