import React from "react";
import img2 from "../imgs/installing-solar-panels.webp"
import img3 from "../imgs/auto-mech.jpg"
import img1 from "../imgs/contruct.jpg"


const Service = ()=>{
return(    <div>
        <div className="container">
            <div className="row" id="service">
                <div className="row">
                    <div className="col-3 barline"> </div>
                    <div className="col-6">
                     <h1 className="text-center">What We Do</h1>
                    </div>
                    <div className="col-3 barline"> </div>
                </div>
               
                <div className="col-md-4">
                    <h4 className="text-center py-5">Solar System Installation</h4>
                    <div className="card" data-aos="fade-right"
                        data-aos-offset="200"
                        data-aos-delay="100"
                        data-aos-easing="ease-in-sine">
                    <div className="card-head">
                    <img src={img2} class="card-img-top" alt="..." height={290}/>
                    </div>
                    <div className="card-body">
                        <p>
                        At Beloved Artisan Solar System Installation, we provide affordable, reliable, and efficient off-grid solar systems for all domestic and commercial purposes. Say goodbye to electric bills, power outages and fluctuations by utilizing the power of a God-given resource; the sun! We have proven to be the best solar installation company in Ghana.
                        </p>
                    </div>
                 </div>
                </div>
                <div className="col-md-4" data-aos="fade-up"
                    data-aos-offset="500"
                    data-aos-delay="100"
                    data-aos-easing="ease-in-sine">
                    <h4 className="text-center py-5">Building and Construction </h4>
                    <div className="card" >
                    <div className="card-head">
                    <img src={img1} class="card-img-top" alt="..."/>
                    </div>
                    <div className="card-body">
                        <p>
                        We offers comprehensive construction services that include, residential construction, commercial construction, industrial construction, and infrastructure construction, site planning, interior design, ultramodern roofing, electrical wiring and installation, geotechnical and engineering analysis, and more. We also offer our clients with the best refined and quality selected paints that makes their structure enviable to look. We have proven to be the best construction company in Ghana.
                        </p>
                    </div>
                 </div>
                </div>
               
                <div className="col-md-4" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine">
                 <h4 className="text-center pt-5 pb-4">Automotive Mechanical and Electrical Works </h4>
                 <div className="card">
                    <div className="card-head">
                    <img src={img3} class="card-img-top" alt="..."/>
                    </div>
                    <div className="card-body">
                        <p>
                        Our automotive mechanical works primarily focuses on diagnosing, repairing, and maintaining internal combustion engines and the various components that make up a vehicle's mechanical systems, such as the transmission, suspension, and brakes. 
                        On the other hand, our automotive electrical works focuses in the electrical systems of vehicles which includes diagnose and repair issues related to a vehicle's electrical components, such as the wiring, battery, starter, alternator, and other electronic systems. We also work on installing and troubleshooting electronic accessories, such as sound systems, alarms, and navigation systems. We have proven to be the best automotive repair and maintenance workshop in Ghana.
                        </p>
                    </div>
                 </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Service